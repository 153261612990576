import { CardContentStyle, CardStyle } from '@common/components/_pages/styles'

import { AuthFormWrapper } from '@features/auth/components/styles'
import { AuthLayout } from '@layouts/auth-layout'
import Brand from '@common/components/brand'
import LoginForm from '@features/auth/components/login'
import { Card } from '@palqee/ui'
import { GetServerSidePropsContext, NextPage } from 'next'
import { TPageProps } from '@app-page/types'
import { LoginProps } from '@features/auth/types/login'
import { getAuthCookieValue } from '@features/auth/lib/cookie/auth'

const AuthLoginPage: NextPage<{}> & TPageProps = (props: LoginProps) => {
  return (
    <AuthFormWrapper>
      <LoginForm {...props} />
    </AuthFormWrapper>
  )
}

export const getServerSideProps = (ctx: GetServerSidePropsContext) => {
  // get rememberMe cookie
  const pqAuthCookie = getAuthCookieValue(ctx.req, ctx.res)

  return {
    props: { pqAuthCookie },
  }
}

AuthLoginPage.getLayout = (page) => {
  return (
    <AuthLayout
      metaTitleFormatMessage={{
        message: {
          id: 'page.head.title.login',
          defaultMessage: 'Login | Palqee',
        },
      }}
      backgroundIcon="welcome-full"
    >
      <Card css={CardStyle}>
        <Card.Content css={CardContentStyle}>{page}</Card.Content>
      </Card>

      <Brand.CopyRight />
    </AuthLayout>
  )
}

export default AuthLoginPage
